import { GhostAPI, PostOrPage, PostsOrPages, Tag, Tags } from '@tryghost/content-api';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import { subscribe } from './api-helpers/subscriber';
import Footer from './components/footer';
import Header from './components/header';
import { AppContext } from './context/app-context';
import withContentApi from './hoc/withContentApi';
import HomePage from './pages/homepage';
import SectionFrontV2 from './pages/section-front-v2';
import VideoPage from './pages/video-page';
import { validateEmail } from './helpers/date-time';
import { getPostTrending } from './api-helpers/trending-api';
import { AxiosResponse } from 'axios';
import { getPosts, GuideTag } from './api-helpers/data-transformer';

const ArticleDetailsPage = lazy(() => import('./pages/article-details-page'));
const GlossaryPage = lazy(() => import('./pages/glossary'));
const SearchResultsPage = lazy(() => import('./pages/search-results'));
const NotFoundPage = lazy(() => import('./pages/404-page'));
const RankingPage = lazy(() => import('./pages/ranking-page'));
const TagsFrontPage = lazy(() => import('./pages/tags-front'));
const TagsFeedPage = lazy(() => import('./pages/tags-feed'));
const AuthorPage = lazy(() => import('./pages/author-page'));
const VideoDetailsPage = lazy(() => import('./pages/video-details-page'));

interface IProps {
  api?: GhostAPI;
}

const App: React.FC<IProps> = ({ api }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [subscribeText, setSubscribeText] = useState('Thank you for subscribing our blog!');
  const [loading, setLoading] = useState(false);
  const [trendingSlugs, setTrendingSlugs] = useState<string[]>([]);
  const [trendingPosts, setTrendingPosts] = useState<PostOrPage[]>([]);
  const [allPosts, setAllPosts] = useState<PostOrPage[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  
  const onSubscribe = useCallback((email: string, fromForm: string): void => {
    if (!validateEmail(email)) {
      return window.alert('Please input a valid email address');
    }

    setLoading(true);
    subscribe(email, fromForm, (alreadySubscribed: boolean) => {
      setSubscribed(true);
      setLoading(false);

      if (alreadySubscribed) {
        setSubscribeText('User already subscribed!');
      }
    }, () => {
      setLoading(false);
      window.alert('Please input a valid email address');
    });
  }, [setSubscribed, setLoading, setSubscribeText]);

  useEffect(() => {
    if (_isEmpty(allPosts) === true) {
      api?.posts.browse({ limit: 'all', include: ['tags', 'authors'] }).then((res: PostsOrPages) => {
        setAllPosts(_map(_map(_omit(res, 'meta')) as PostOrPage[], (post: PostOrPage) => ({ ...post, html: post.html?.replace(/(<([^>]+)>)/ig, '') })));
      });
    }
  }, [allPosts, api]);


  useEffect(() => {
    api?.tags.browse({ limit: 1000, include: ['count.posts'] }).then((res: Tags) => {
      setAllTags(_map(_omit(res, 'meta')) as Tag[]);
    });
  }, [api]);

  useEffect(() => {
    if (_isEmpty(trendingSlugs) === true) {
      getPostTrending().then((res: AxiosResponse<string[]>) => {
        setTrendingSlugs(res.data);
      });
    }
  }, [trendingSlugs]);

  useEffect(() => {
    if (_isEmpty(trendingSlugs) === false) {
      api?.posts.browse({ limit: 'all', filter: `slug:[${trendingSlugs.map((t: string) => t.replace('articles/', '')).join(',')}]`, include: ['tags', 'authors'] }).then((res: PostsOrPages) => {
        setTrendingPosts(getPosts(res).sort((postA: PostOrPage, postB: PostOrPage) => trendingSlugs.indexOf(postA.slug) - trendingSlugs.indexOf(postB.slug)));
      });
    }
  }, [api, trendingSlugs]);

  const newToTimePiecesPosts: PostOrPage[] = useMemo(() => _filter(
    allPosts,
    (post: PostOrPage) => {
      const tags: string[] = _map(
        (post?.tags || [])
          .concat([_get(post, 'primary_tag', [])] as Tag[]),
        'slug');

      return _includes(tags, GuideTag) && _includes(tags.join(' '), 'watch');
    }), [allPosts]);

  const featuredPosts: PostOrPage[] = useMemo(() => {
    return _filter(
        allPosts,
        (post: PostOrPage) => post.featured === true)
  }, [allPosts]);

  return (
    <>
      <Helmet>
        <title>The Parlor - Blog by Jomashop</title>
        <meta name='description' content='Jomashop Blog is your goto for news, reviews, and industry insights on watches, handbags, sunglasses, fragrances, and luxury fashion clothing shoes and accessories.' />
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <AppContext.Provider value={{
        hasSubscribed: subscribed,
        onSubscribe,
        allPosts,
        subscribeText,
        allTags,
        trendingPosts,
        newToTimePiecesPosts,
        featuredPosts
      }}>
        <div className={`flex flex-col w-full min-h-screen ${location.pathname === '/glossary' ? '' : 'overflow-x-hidden'}`}>
          <div className="bg-black py-1 px-16px">
            <div className='flex flex-col flex-grow max-w-md-container xl:max-w-container mx-auto w-full'>
              {!/^\/blog\/?$/.test(window.location.pathname) ?
                <span className='inline-flex flex-grow cursor-pointer' onClick={() => navigate(-1)}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='white'>
                    <path d='M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z'></path>
                  </svg>
                  <span className='text-base text-white'>Back</span>
                </span> :
                <a href='/' className='inline-flex flex-grow cursor-pointer'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='white'>
                    <path d='M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z'></path>
                  </svg>
                  <span className='text-base text-white'>Go to Jomashop</span>
                </a>}
              
            </div>
          </div>
          <div className='flex flex-col flex-grow max-w-md-container xl:max-w-container mx-auto w-full'>
            <div className='px-16px lg:px-0'>
              <Header />
            </div>
            <div className='px-16px py-25px sm:pb-60px lg:px-0 flex-grow'>
              <Routes>
                <Route path='/articles/:slug' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ArticleDetailsPage />
                  </Suspense>
                } />
                <Route path='/guides/:slug' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ArticleDetailsPage />
                  </Suspense>
                } />
                <Route path='/video/:videoId' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <VideoDetailsPage />
                  </Suspense>
                } />
                <Route path='/video' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <VideoPage />
                  </Suspense>
                } />
                <Route path='/search-results' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SearchResultsPage />
                  </Suspense>
                } />
                <Route path='/articles/category/:categoryId' element={<SectionFrontV2 />} />
                <Route path='/guides/category/:categoryId' element={<SectionFrontV2 />} />
                <Route path='/articles/category' element={<SectionFrontV2 />} />
                <Route path='/guides/category' element={<SectionFrontV2 />} />
                <Route path='/articles' element={<SectionFrontV2 />} />
                <Route path='/guides' element={<SectionFrontV2 />} />
                <Route path='/glossary' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <GlossaryPage />
                  </Suspense>
                } />
                <Route path='/trending' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <TagsFrontPage breakcrumbTitle='Trending' tags={[]} posts={trendingPosts} />
                  </Suspense>
                } />
                <Route path='/featured' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <TagsFrontPage breakcrumbTitle='Featured' tags={[]} posts={featuredPosts} />
                  </Suspense>
                } />
                <Route path='/beginner-watch-guides' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <TagsFrontPage breakcrumbTitle='Beginner Watch Guides' tags={[]} posts={newToTimePiecesPosts} />
                  </Suspense>
                } />
                <Route path='/tags/:tagSlug' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <TagsFeedPage />
                  </Suspense>
                } />
                <Route path='/author/:authorSlug' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AuthorPage />
                  </Suspense>
                } />
                <Route path='/ranking' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <RankingPage />
                  </Suspense>
                } />
                <Route path='*' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <NotFoundPage />
                  </Suspense>
                } />
                <Route path='/' element={<HomePage />} />
              </Routes>
            </div>
          </div>
          <div className='w-full bg-jm-light'>
            <div className='flex flex-col flex-grow max-w-md-container xl:max-w-container mx-auto w-full'>
              <Footer />
            </div>
          </div>
        </div>
        {
          loading && (
            <div className='w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50'>
              <span className='text-green-500 opacity-75 top-1/2 my-0  block relative w-full h-0 '>
                <svg fill='none' className='h-100 w-100 mx-auto animate-spin' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
                  <path clipRule='evenodd'
                    d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                    fill='currentColor' fillRule='evenodd' />
                </svg>
              </span>
            </div>
          )
        }
      </AppContext.Provider>
    </>

  )
}

export default withContentApi<IProps>(App);
