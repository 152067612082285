import React, { memo } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  linkUrl?: string;
  title: string;
  total?: number;
  customSpacing?: string;
}

const SectionTitle: React.FC<IProps> = ({ title, total, linkUrl = '', customSpacing }) => (
  <div className={`flex justify-between sm:mt-0 sm:mb-22px lg:my-25px items-center ${customSpacing ? customSpacing : 'mb-30px mt-8 sm:pt-9px'}`}>
    <div className='font-bold text-trending-title lg:text-28 lg:leading-23'>{title}</div>
    <div className={`border-b flex-grow border-jm-gray-content border-opacity-40 text-center ${linkUrl === '' ? 'ml-3 sm:ml-8' : 'mx-3 sm:mx-8'}`} />
    {
      linkUrl !== '' && !!total && (
        <div className='font-segoe text-16 leading-23 text-jm-gray-content'>
          <Link to={linkUrl}>View all {title.toLowerCase() === 'videos' ? 'videos' : 'posts'} ({total})</Link>
        </div>
      )
    }
  </div>
);

export default memo(SectionTitle);
