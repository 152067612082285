import { Author, GhostAPI, PostOrPage } from '@tryghost/content-api';
import React, { FC, memo, useMemo } from 'react';
import SectionTitle from '../components/section-title';
import { Helmet } from 'react-helmet';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _sampleSize from 'lodash/sampleSize';
import StoryCard from '../components/story-card';

interface IProps {
  api?: GhostAPI;
  sectionTitle?: string;
  sectionPath?: string;
  className?: string;
  posts?: PostOrPage[];
  author?: Author;
}

const SectionFeatured: FC<IProps> = (props) => {
  const {
    posts,
    sectionTitle,
    sectionPath
  } = props

  const displayOnes = useMemo(() => (typeof posts === 'undefined' ? [] : posts).slice(0, 5), [posts]);

  const dummyArticles: PostOrPage[] = useMemo(() => {
    if (!posts) return []

    if (posts.length > 3) {
      return _sampleSize(posts, 3)
    }

    return posts;
  }, [posts]);

  return (
    <>
      <Helmet>
        <meta name='description' content={`Read about: ${displayOnes.map(item => item.title).join(', ')}`}/>
      </Helmet>
      {
        _isEmpty(sectionTitle) === false && (
          <div className='my-3 sm:mt-0 lg:my-3'>
            <SectionTitle
              title={sectionTitle || ''}
              total={posts && posts?.length > 3 ? posts?.length : 0}
              linkUrl={sectionPath}
            />
          </div>
        )
      }
      <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-x-3 lg:grid-cols-3'>
        {
          _map(dummyArticles, (article: PostOrPage) => {
            return  <StoryCard
              key={article?.id || Math.random().toString()}
              isFeature={article.featured}
              isCompact
              article={article}
            />
          })
        }
      </div>
    </>
  )
}

export default memo(SectionFeatured)